import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Text } from "../../components/Core";
import { StaticImage } from "gatsby-plugin-image";
import { ContentImageAnimationFadeRight } from ".";

const SectionStyled = styled(Section)``;

const Content1 = () => {
  return (
    <>
      {/* <!-- Content1 section --> */}
      <SectionStyled>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="mb-4 mb-lg-0 pl-lg-5 order-lg-2">
              <div
                {...ContentImageAnimationFadeRight}
                style={{
                  padding: "48px",
                }}
              >
                <StaticImage
                  src="../../assets/image/png/illustration-values.png"
                  alt="values illustration"
                  placeholder="none"
                />
              </div>
            </Col>
            <Col lg="6" md={9} className="order-lg-1">
              <div>
                <Title>
                  Guided by{" "}
                  <br className="d-none d-sm-block d-md-none d-xl-block" />
                  Your Values
                </Title>
                <Text>
                  Start with your <b>why</b> — what’s important to you and how
                  you want to use your money.
                  <br />
                  <br />
                  Life is more than just the average budgeting categories like
                  Bills, Transportation, Insurance, etc. It’s time your
                  financial tool reflects that.
                </Text>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content1;
