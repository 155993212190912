import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text } from "../../components/Core";
import { StaticImage } from "gatsby-plugin-image";
import { ContentImageAnimationFadeLeft } from ".";

const SectionStyled = styled(Section)``;

const Content4 = () => {
  return (
    <>
      {/* <!-- Content2 section --> */}
      <SectionStyled pt="0!important">
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="mb-4 mb-lg-0">
              <div
                {...ContentImageAnimationFadeLeft}
                style={{
                  padding: "48px",
                  paddingLeft: 0,
                }}
              >
                <StaticImage
                  src="../../assets/image/png/illustration-secure.png"
                  alt="relaxing illustration"
                  placeholder="none"
                />
              </div>
            </Col>
            <Col lg="6" md={9}>
              <div>
                <Title>Secure and Private</Title>
                <Text>
                  Allo uses bank-level security, and we do not store your
                  financial credentials. We will never show you distracting ads
                  or sell your financial data.
                  <br />
                  <br />
                  We are committed to building the best product to improve your
                  financial life.
                </Text>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content4;
