import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Text } from "../../components/Core";
import { rgba } from "polished";

const A = styled.a`
  text-decoration: underline;
`;

const BACKGROUND_COLOR = rgba("#81b29a", 0.45);

const Content = () => (
  <>
    <Box py={3} bg={BACKGROUND_COLOR} className="position-relative">
      <Container>
        <Row className="justify-content-center">
          <Col lg="8">
            <Text className="text-center" variant="small">
              Learn more about <A href="/why-allo">why we are building Allo</A>{" "}
              &#8594;
            </Text>
          </Col>
        </Row>
      </Container>
    </Box>
  </>
);

export default Content;
