import React from "react";
import Hero from "../sections/home/Hero";
import WhyWeBuiltAllo from "../sections/home/WhyWeBuiltAllo";
import Content1 from "../sections/home/Content1";
import Content2 from "../sections/home/Content2";
import Content3 from "../sections/home/Content3";
import Content4 from "../sections/home/Content4";
import HowItWorks from "../sections/home/HowItWorksSlider";
import Cta from "../sections/home/CTA";
import Faq from "../sections/home/FAQ";

import PageWrapper from "../components/PageWrapper";
import Reviews from "../sections/home/Reviews";

const Home = () => {
  return (
    <>
      <PageWrapper headerDark footerDark>
        <Hero />
        <WhyWeBuiltAllo />
        <Reviews />
        <Content1 />
        <Content2 />
        <Content3 />
        <Content4 />
        <HowItWorks />
        <Faq />
        <Cta />
      </PageWrapper>
    </>
  );
};
export default Home;
