import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Text, Button } from "../../components/Core";
import { device } from "../../utils";

const SectionStyled = styled(Section)`
  border-bottom: 1px solid #eae9f2;
`;

const CtaButton = styled(Button)`
  margin-top: 40px;
  height: 72px;

  @media ${device.sm} {
    min-width: 400px;
  }
`;

const CTA = () => (
  <>
    <SectionStyled className="position-relative">
      <Container>
        <Row className="justify-content-center">
          <Col md="8" lg="7" xl="6">
            <div className="text-center">
              <Title>Ready to Start?</Title>
              <Text>Start your mindful money practice with Allo.</Text>
              <CtaButton
                onClick={() => {
                  window.location.href = "/download/";
                }}
              >
                Try Allo for Free
              </CtaButton>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default CTA;
