import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text } from "../../components/Core";

import { StaticImage } from "gatsby-plugin-image";
import { ContentImageAnimationFadeRight } from ".";

const SectionStyled = styled(Section)``;

const Content3 = () => {
  return (
    <>
      {/* <!-- Content3 section --> */}
      <SectionStyled>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="mb-4 mb-lg-0 pl-lg-5 order-lg-2">
              <div
                {...ContentImageAnimationFadeRight}
                style={{
                  paddingLeft: "80px",
                  paddingRight: "80px",
                }}
              >
                <StaticImage
                  src="../../assets/image/svg/illustration-race.svg"
                  alt="accomplished illustration"
                  placeholder="none"
                />
              </div>
            </Col>
            <Col lg="6" md={9} className="order-lg-1">
              <div>
                <Title>Built for Change</Title>
                <Text>
                  We won’t lie. Real, material change in your financial life is
                  hard and only achievable with time and patience. That’s why
                  Allo helps build a habit that can last.
                  <br />
                  <br />
                  Allo sessions are deliberately kept simple and based on
                  positive reinforcements. So it's easy to get started, and easy
                  to stick with it.
                </Text>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content3;
