export const ContentImageAnimationCommon = {
  "data-aos-duration": "750",
  "data-aos-delay": "0",
  "data-aos-once": "true",
};

export const ContentImageAnimationFadeLeft = {
  "data-aos": "fade-left",
  ...ContentImageAnimationCommon,
};

export const ContentImageAnimationFadeRight = {
  "data-aos": "fade-right",
  ...ContentImageAnimationCommon,
};
