import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import ReviewCard from "../../components/ReviewCard";

const Reviews = () => {
  return (
    <>
      <Section bg="#f6f6f8" borderBottom="1px solid #EAE9F2;">
        <Container>
          <Row className="justify-content-center">
            <Col md="9" lg="6" className="text-center">
              <Box className="text-center">
                <Title>Join the Community</Title>
              </Box>
              <Text mb={4}>
                Every day, people use Allo to feel great about their finances
                and transform their relationship with money.
              </Text>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="justify-content-center mt-3">
            <Col xl="4" md="6" className="mb-4">
              <ReviewCard
                name="Alex D"
                stars={5}
                title="Allo has changed the way I spend and think."
              >
                "I’ve tried all the budgeting apps and while I’m not bad with
                money, my mindset is. The categories being connected to values
                has opened my eyes to how I’m spending. It’s been a freeing
                experience!"
              </ReviewCard>
            </Col>
            <Col xl="4" md="6" className="mb-4">
              <ReviewCard
                name="Katie S"
                stars={5}
                title="Feeling at peace with finances!"
              >
                "The periodic element of Allo helps me keep on top of things
                without avoiding for too long and having sticker shock. Plus,
                for the first time I’m able to frame my finances in a light that
                makes me feel good!"
              </ReviewCard>
            </Col>
            <Col xl="4" md="6" className="mb-4">
              <ReviewCard
                name="Maria C"
                stars={5}
                title="The first money app I actually like!"
              >
                "I look forward to using Allo everyday, which I have never said
                about any money app ... [It] is changing my behavior, in really
                gentle ways — that are gonna last."
              </ReviewCard>
            </Col>
            <Col xl="4" md="6" className="mb-4">
              <ReviewCard
                name="Susie T"
                stars={5}
                title="Healed my relationship with money!"
              >
                "Unlike other budgeting apps, Allo helped me focus on my values
                ... It illuminated areas where I could cut back ... and areas I
                could release some anxiety around."
              </ReviewCard>
            </Col>
            <Col xl="4" md="6" className="mb-4">
              <ReviewCard
                name="Jane W"
                stars={5}
                title="I feel relaxed and grateful."
              >
                "It’s rewarding to create a gratitude practice around my money.
                My check-ins with Allo help me stay relaxed and grateful."
              </ReviewCard>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Reviews;
