import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text } from "../../components/Core";
import { StaticImage } from "gatsby-plugin-image";
import { ContentImageAnimationFadeLeft } from ".";

const SectionStyled = styled(Section)``;

const Content2 = () => {
  return (
    <>
      {/* <!-- Content2 section --> */}
      <SectionStyled pt="0!important">
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="mb-4 mb-lg-0">
              <div
                {...ContentImageAnimationFadeLeft}
                style={{
                  padding: "48px",
                  paddingLeft: 0,
                }}
              >
                <StaticImage
                  src="../../assets/image/png/illustration-relax.png"
                  alt="relaxing illustration"
                  placeholder="none"
                />
              </div>
            </Col>
            <Col lg="6" md={9}>
              <div>
                <Title>
                  Designed for{" "}
                  <br className="d-none d-sm-block d-md-none d-xl-block" />
                  Peace of Mind
                </Title>
                <Text>
                  A regular practice with Allo — whether daily, weekly, or
                  monthly — lets you be confidently aware of your finances,
                  while not being so obsessed with it that you are trapped in
                  your financial app 24/7.
                  <br />
                  <br />
                  It's about meaningfully engaging with money, then setting down
                  the phone, and living your life anxiety-free.
                </Text>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content2;
