import React from "react";
import styled from "styled-components";

import { Box } from "../../components/Core";

import imgL1Brand1 from "../../assets/image/png/google-play-new-apps-we-love.png";
import imgL1Brand2 from "../../assets/image/png/wired.png";
import imgL1Brand3 from "../../assets/image/png/techcrunch.png";
import { device } from "../../utils";

const Brand = styled(Box)`
  display: flex;
  align-items: center;
  opacity: 0.7;
  transition: all 0.3s ease-out;
  &:hover {
    opacity: 1;
  }
  height: 80px;

  @media ${device.md} {
    height: 54px;
  }

  @media ${device.lg} {
    height: 64px;
  }

  margin-bottom: 8px;
`;

const BrandImage = styled.img`
  max-height: 100%;
`;

const FeaturedLogos = () => (
  <>
    {/* <!-- Clients Brands Area --> */}
    <Box className="pt-5 pb-2 pb-md-4">
      <Box
        className="d-flex justify-content-center justify-content-lg-between
         align-items-center flex-wrap pt-2 pt-md-0"
        ml={["0px", "0px", "-16px"]}
        mr={["0px", "0px", "-32px"]}
      >
        <Brand className="mt-3 mt-md-0" py={1} mx={3}>
          <BrandImage src={imgL1Brand1} alt="" className="img-fluid" />
        </Brand>
        <Brand className="mt-2 mt-md-0" py={3} mx={3}>
          <BrandImage src={imgL1Brand2} alt="" className="img-fluid" />
        </Brand>
        <Brand className="" py={3} mx={3}>
          <BrandImage src={imgL1Brand3} alt="" className="img-fluid" />
        </Brand>
      </Box>
    </Box>
  </>
);

export default FeaturedLogos;
